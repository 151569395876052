html,
body {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
}

.ant-layout {
    background: #F8F8FA;
}

.ant-layout-sider {
    background: #333547;
    color: rgba(255, 255, 255, 0.7);
}

.ant-menu-submenu>.ant-menu {
    background: #333547;
    color: rgba(255, 255, 255, 0.7);
}

.ant-menu-submenu-open .ant-menu-submenu-title {
    background: #383B4E;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #383B4E;
}

.ant-menu-item-selected {
    color: #FFF;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
}

.ant-menu-item::after {
    border-right: 3px solid #626ed4;
}


.ant-table-wrapper {
    margin: 30px 0;
    background: #FFF;
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}

.ant-page-header {
    background: none;
    padding: 0;
    margin: 0 0 20px;
}

.ant-page-header,
.ant-breadcrumb {
    font-size: 12px;
}

.ant-page-header-title-view {
    display: flex;
    flex-direction: column;
}

.ant-page-header-title-view-title {
    font-weight: 600;
    font-size: 24px;
    font-family: 'Raleway'
}




/** Modal Window **/
.ant-drawer-title {
    font-weight: 600;
    font-size: 18px;
    font-family: 'Raleway'
}

.ant-drawer-mask {
    opacity: 0.9;
}

.ant-drawer-body {
    padding: 30px;
}


.ant-filter-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-filter-form .ant-form-item {
    display: flex;
}

.ant-filter-form .ant-form-item-control-wrapper {
    flex: 1;
}

.ql-editor .ql-align-justify {
    text-align: justify;
    white-space: normal;
}


.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}